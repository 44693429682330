import { cssVar } from '@chakra-ui/react';
import type { Dict } from '@chakra-ui/utils';
import { getFontFamilyPair, isCursiveFont } from 'ts/common/fonts';

type IParams = Pick<
    SpApi.IBrandTheme,
    'colorScheme' | 'fontSet' | 'primaryColor' | 'secondaryColor'
>;

const openSansFont = 'open-sans, sans-serif';

const getTheme = ({ colorScheme, fontSet, primaryColor, secondaryColor }: IParams): Dict => {
    const { primaryFontFamily, secondaryFontFamily } = getFontFamilyPair(fontSet);
    const isNotCursiveFont = !isCursiveFont(fontSet);
    const headingFontFamily = isNotCursiveFont ? primaryFontFamily : secondaryFontFamily;
    const headingFontWeight = fontSet === 'brandon' ? 'bold' : 'normal';
    const headingLetterSpacing = isNotCursiveFont ? 'wider' : 'initial';
    const headingTextTransform = isNotCursiveFont ? 'uppercase' : 'initial';
    const isBambusaFont = fontSet === 'bambusa-pro-regular';
    const isBloomingElegantFont = fontSet === 'blooming-elegant';
    const isLightColorScheme = colorScheme === 'light';
    const dropShadow = `drop-shadow(0px 0px 12px rgba(0,0,0,${
        isLightColorScheme ? '0.2' : '0.8'
    }))`;

    const theme: Dict = {
        colors: {
            white: '#FFFFFF',
            whiteAlpha: {
                500: 'rgba(255, 255, 255, 0.4)',
                700: 'rgba(255, 255, 255, 0.8)'
            },
            blackAlpha: {
                500: 'rgba(0, 0, 0, 0.4)',
                700: 'rgba(0, 0, 0, 0.8)'
            },
            gray: {
                25: isLightColorScheme ? '#F7F9FA' : '#1A1A1A',
                50: isLightColorScheme ? 'rgba(100,100,100,0.1)' : 'rgba(155,155,155,0.2)',
                100: isLightColorScheme ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.2)',
                200: '#E0E3E5',
                300: '#ABB0B2',
                400: '#6E7173',
                500: '#494B4C',
                600: '#303233',
                700: isLightColorScheme ? '#191717' : '#121212'
            },
            red: {
                200: '#FFDEDB',
                300: '#FFB9B2',
                400: '#FF7D73',
                600: '#8C1207',
                700: '#520700'
            },
            background: isLightColorScheme
                ? 'var(--chakra-colors-white)'
                : 'var(--chakra-colors-gray-700)',
            paper: isLightColorScheme
                ? 'var(--chakra-colors-white)'
                : 'var(--chakra-colors-gray-25)',
            paperBackground: isLightColorScheme
                ? 'var(--chakra-colors-gray-25)'
                : 'var(--chakra-colors-gray-700)',

            boxShadow: isLightColorScheme ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.04)',
            modalBackground: isLightColorScheme ? 'white' : 'var(--chakra-colors-gray-600)',
            text: isLightColorScheme
                ? 'var(--chakra-colors-gray-500)'
                : 'var(--chakra-colors-white)',
            darkText: isLightColorScheme
                ? 'var(--chakra-colors-gray-700)'
                : 'var(--chakra-colors-white)',
            brand: {
                primary: `#${primaryColor}`,
                primaryOverlay: `#${primaryColor}99`,
                primaryMedium: `#${primaryColor}20`,
                primaryLight: `#${primaryColor}10`,
                secondary: `#${secondaryColor}`
            }
        },
        components: {
            Button: {
                baseStyle: {
                    borderRadius: 'full',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    fontFamily: openSansFont,
                    fontWeight: 'normal',
                    _focus: {
                        boxShadow: 'none'
                    },
                    _disabled: {
                        pointerEvents: 'none'
                    }
                },
                sizes: {
                    md: {
                        fontSize: 'md',
                        height: '48px',
                        letterSpacing: '0.5px',
                        lineHeight: 'base',
                        px: '20px',
                        py: '12px'
                    }
                },
                variants: {
                    primary: {
                        backgroundColor: 'brand.primary',
                        borderColor: 'brand.primary',
                        color: isLightColorScheme ? 'white' : 'gray.700',
                        _active: {
                            backgroundColor: 'brand.primary'
                        },
                        _after: {
                            backgroundColor: 'transparent',
                            borderRadius: 'full',
                            content: '""',
                            height: 'full',
                            position: 'absolute',
                            transitionDuration: 'normal',
                            transitionProperty: 'common',
                            width: 'full'
                        },
                        _disabled: {
                            opacity: 0.5
                        },
                        _focus: {
                            color: isLightColorScheme ? 'white' : 'gray.700'
                        },
                        _focusVisible: {
                            boxShadow: `var(--chakra-shadows-outline)`
                        },
                        _hover: {
                            backgroundColor: 'brand.primary',
                            color: isLightColorScheme ? 'white' : 'gray.700',
                            position: 'relative',
                            _after: {
                                backgroundColor: 'gray.100'
                            },
                            _disabled: {
                                background: '' // set by default to 'initial' which is not desired
                            }
                        }
                    },
                    'secondary dark': {
                        backgroundColor: 'transparent',
                        borderColor: 'gray.700',
                        color: 'gray.700',
                        _active: {
                            backgroundColor: 'gray.700'
                        },
                        _disabled: {
                            opacity: 0.5
                        },
                        _focus: {
                            color: 'white',
                            backgroundColor: 'gray.700',
                            borderColor: 'gray.700'
                        },
                        _focusVisible: {
                            boxShadow: `var(--chakra-shadows-outline)`
                        },
                        _hover: {
                            backgroundColor: 'gray.700',
                            borderColor: 'gray.700',
                            color: 'white'
                        }
                    },
                    'secondary light': {
                        backgroundColor: 'transparent',
                        borderColor: 'white',
                        color: 'white',
                        _active: {
                            backgroundColor: 'white'
                        },
                        _disabled: {
                            opacity: 0.5
                        },
                        _focus: {
                            color: 'gray.700',
                            backgroundColor: 'white',
                            borderColor: 'white'
                        },
                        _focusVisible: {
                            boxShadow: `var(--chakra-shadows-outline)`
                        },
                        _hover: {
                            backgroundColor: 'white',
                            borderColor: 'white',
                            color: 'gray.700'
                        }
                    },
                    ghost: {
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'text',
                        fontSize: 'sm',
                        lineHeight: 'base',
                        paddingX: '16px',
                        _disabled: {
                            opacity: 0.5
                        },
                        _active: {
                            backgroundColor: 'transparent'
                        },
                        _focus: {
                            backgroundColor: 'transparent',
                            color: 'text'
                        },
                        _focusVisible: {
                            boxShadow: 'outline',
                            color: 'brand.primary'
                        },
                        _hover: {
                            backgroundColor: 'transparent',
                            color: 'brand.primary'
                        }
                    },
                    'ghost primary': {
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'brand.primary',
                        fontSize: 'sm',
                        lineHeight: 'base',
                        paddingX: '16px',
                        _disabled: {
                            opacity: 0.5
                        },
                        _active: {
                            backgroundColor: 'transparent'
                        },
                        _focus: {
                            backgroundColor: 'transparent',
                            color: 'brand.primary'
                        },
                        _focusVisible: {
                            boxShadow: 'outline',
                            color: 'brand.primary'
                        },
                        _hover: {
                            backgroundColor: 'transparent',
                            color: 'brand.primary'
                        }
                    },
                    googlePhotos: {
                        color: 'gray.700',
                        border: isLightColorScheme ? '1px solid gray.700' : 'none',
                        backgroundColor: 'white',
                        _focus: {
                            color: 'gray.700'
                        },
                        _focusVisible: {
                            boxShadow: `var(--chakra-shadows-outline)`
                        },
                        _hover: {
                            backgroundColor: 'white',
                            color: 'gray.700'
                        }
                    },
                    'outline primary': {
                        backgroundColor: 'transparent',
                        borderColor: 'brand.primary',
                        color: 'brand.primary',
                        lineHeight: 'base',
                        paddingX: '16px',
                        _disabled: {
                            opacity: 0.5
                        },
                        _active: {
                            backgroundColor: 'transparent'
                        },
                        _focus: {
                            backgroundColor: 'transparent',
                            color: 'brand.primary'
                        },
                        _focusVisible: {
                            boxShadow: 'outline',
                            color: 'brand.primary'
                        },
                        _hover: {
                            backgroundColor: 'brand.primary',
                            color: isLightColorScheme ? 'white' : 'gray.700',
                            position: 'relative',
                            _after: {
                                backgroundColor: 'gray.100'
                            },
                            _disabled: {
                                background: '' // set by default to 'initial' which is not desired
                            }
                        }
                    },
                    text: {
                        color: 'brand.primary',
                        padding: 0,
                        fontWeight: 'semibold',
                        fontSize: '14px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        verticalAlign: 'baseline',
                        textDecoration: 'underline',
                        _hover: {
                            backgroundColor: 'transparent',
                            color: 'brand.primary'
                        }
                    }
                }
            },
            Checkbox: {
                baseStyle: {
                    control: {
                        borderColor: 'text',
                        _checked: {
                            color: 'black',
                            background: 'brand.primary',
                            borderColor: 'brand.primary',
                            _hover: {
                                background: 'brand.primary',
                                borderColor: 'brand.primary'
                            }
                        },
                        _focus: {
                            borderColor: 'brand.primary',
                            borderWidth: '2px'
                        }
                    },
                    icon: {
                        color: isLightColorScheme ? 'black' : 'white'
                    },
                    label: {
                        color: 'text',
                        fontWeight: 'normal'
                    }
                },
                sizes: {
                    xl: {
                        control: {
                            boxSize: 26
                        }
                    }
                },
                variants: {
                    round: {
                        control: {
                            borderRadius: '100%',
                            color: isLightColorScheme ? 'white' : 'gray.700',
                            borderColor: isLightColorScheme ? 'white' : 'gray.700',
                            background: 'gray.100',
                            ' svg': {
                                width: '12px',
                                height: '12px'
                            },
                            _checked: {
                                color: isLightColorScheme ? 'white' : 'gray.700',
                                borderColor: isLightColorScheme ? 'white' : 'gray.700',
                                background: 'brand.primary',
                                _hover: {
                                    borderColor: isLightColorScheme ? 'white' : 'gray.700',
                                    background: 'brand.primary'
                                }
                            }
                        }
                    }
                }
            },
            DatePicker: {
                baseStyle: {
                    fontFamily: 'open-sans, sans-serif',
                    backgroundColor: 'var(--chakra-colors-brand-primary)',
                    headerBackgroundColor: isLightColorScheme ? 'white' : 'transparent',
                    color: isLightColorScheme ? '#141617' : 'white',
                    disabledColor: isLightColorScheme ? '#cccccc' : 'grey'
                }
            },
            Divider: {
                baseStyle: {
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: isLightColorScheme ? 'gray.200' : 'gray.500'
                },
                variants: {
                    dark: {
                        borderColor: 'gray.700'
                    },
                    light: {
                        borderColor: 'white'
                    },
                    primary: {
                        borderColor: 'brand.primary'
                    }
                }
            },
            Drawer: {
                baseStyle: {
                    dialog: {
                        backgroundColor: 'modalBackground',
                        borderRadius: '20px 20px 0 0',
                        boxShadow: 'none',
                        filter: dropShadow
                    },
                    closeButton: {
                        marginTop: '-48px',
                        borderRadius: 'xl',
                        right: '16px',
                        backgroundColor: isLightColorScheme ? 'whiteAlpha.900' : 'gray.600',
                        color: 'text',
                        _active: {
                            backgroundColor: isLightColorScheme ? 'whiteAlpha.900' : 'gray.600',
                            color: 'text'
                        },
                        _hover: {
                            color: 'text',
                            boxShadow: 'none'
                        },
                        _focus: {
                            color: 'text',
                            boxShadow: 'none'
                        },
                        _focusVisible: {
                            boxShadow: 'outline'
                        }
                    }
                }
            },
            Form: {
                baseStyle: {
                    container: {
                        paddingBottom: 6
                    },
                    requiredIndicator: {
                        color: 'inherit',
                        margin: 0
                    }
                }
            },
            FormError: {
                baseStyle: {
                    text: {
                        color: isLightColorScheme
                            ? 'var(--chakra-colors-red-600)'
                            : 'var(--chakra-colors-red-400)',
                        lineHeight: 'shorter',
                        marginTop: '2px'
                    }
                }
            },
            FormLabel: {
                baseStyle: {
                    color: 'text',
                    fontFamily: openSansFont,
                    marginBottom: '2px',
                    marginRight: '6px',
                    fontWeight: 'normal'
                },
                sizes: {
                    md: {
                        fontSize: 'md',
                        lineHeight: 'base'
                    }
                },
                defaultProps: {
                    size: 'md'
                }
            },
            Heading: {
                sizes: {
                    '3xl': {
                        fontFamily: headingFontFamily,
                        fontSize: isNotCursiveFont ? '3xl' : isBloomingElegantFont ? '5xl' : '4xl',
                        fontWeight: headingFontWeight,
                        letterSpacing: headingLetterSpacing,
                        lineHeight: isNotCursiveFont || isBloomingElegantFont ? 'shorter' : 'base',
                        textTransform: headingTextTransform
                    },
                    '2xl': {
                        fontFamily: headingFontFamily,
                        fontSize: isNotCursiveFont ? '2xl' : isBloomingElegantFont ? '4xl' : '3xl',
                        fontWeight: headingFontWeight,
                        letterSpacing: headingLetterSpacing,
                        lineHeight: isNotCursiveFont || isBloomingElegantFont ? 'shorter' : 'base',
                        textTransform: headingTextTransform
                    },
                    xl: {
                        fontFamily: headingFontFamily,
                        fontSize: isNotCursiveFont ? 'lg' : isBloomingElegantFont ? '3xl' : '2xl',
                        fontWeight: headingFontWeight,
                        letterSpacing: headingLetterSpacing,
                        lineHeight: isNotCursiveFont || isBloomingElegantFont ? 'shorter' : 'base',
                        textTransform: headingTextTransform
                    },
                    lg: {
                        fontFamily: openSansFont,
                        fontSize: 'lg',
                        fontWeight: 'semibold',
                        lineHeight: 'short'
                    },
                    md: {
                        fontFamily: openSansFont,
                        fontSize: 'md',
                        fontWeight: 'semibold',
                        lineHeight: 'base'
                    },
                    sm: {
                        fontFamily: headingFontFamily,
                        fontSize:
                            isNotCursiveFont || isBambusaFont
                                ? '12px'
                                : isBloomingElegantFont
                                ? 'xl'
                                : 'lg',
                        fontWeight: headingFontWeight,
                        letterSpacing: headingLetterSpacing,
                        lineHeight: isNotCursiveFont || isBloomingElegantFont ? 'short' : 'base',
                        textTransform: headingTextTransform
                    },
                    xs: {
                        fontFamily: openSansFont,
                        fontSize: 'sm',
                        letterSpacing: 'wider',
                        lineHeight: 'short',
                        textTransform: 'uppercase'
                    }
                },
                variants: {
                    dark: {
                        color: 'gray.700'
                    },
                    light: {
                        color: 'white'
                    },
                    primary: {
                        color: 'brand.primary'
                    }
                }
            },
            Input: {
                baseStyle: {
                    field: {
                        color: 'text',
                        fontFamily: openSansFont
                    }
                },
                variants: {
                    outline: {
                        field: {
                            borderColor: 'gray.300',
                            paddingX: '16px',
                            _focus: {
                                borderColor: 'brand.primary',
                                borderWidth: '2px',
                                boxShadow: 'none',
                                paddingX: '15px'
                            },
                            _hover: {
                                borderColor: 'brand.primary',
                                borderWidth: '2px',
                                paddingX: '15px'
                            },
                            _invalid: {
                                borderColor: isLightColorScheme ? 'red.600' : 'red.400',
                                borderWidth: '2px',
                                boxShadow: 'none',
                                paddingX: '15px'
                            }
                        }
                    }
                }
            },
            Link: {
                baseStyle: {
                    color: 'brand.primary',
                    _active: {
                        color: 'brand.primary'
                    },
                    _focus: {
                        color: 'brand.primary'
                    },
                    _hover: {
                        color: 'brand.primary'
                    },
                    _visited: {
                        color: 'brand.primary'
                    }
                },
                variants: {
                    googlePhotos: {
                        _focus: {
                            boxShadow: 'none'
                        },
                        _focusVisible: {
                            boxShadow: `var(--chakra-shadows-outline)`
                        }
                    }
                }
            },
            Modal: {
                baseStyle: {
                    body: {
                        bg: 'background',
                        borderRadius: {
                            base: 'none',
                            lg: 'xl'
                        },
                        fontFamily: openSansFont,
                        pb: {
                            base: '24px',
                            lg: '40px'
                        },
                        pt: '40px',
                        px: {
                            base: '26px',
                            lg: '56px'
                        }
                    },
                    closeButton: {
                        color: 'text',
                        borderRadius: 'full',
                        _active: {
                            backgroundColor: isLightColorScheme
                                ? 'blackAlpha.200'
                                : 'whiteAlpha.200'
                        },
                        _focus: {
                            color: 'text',
                            boxShadow: 'none'
                        },
                        _focusVisible: {
                            boxShadow: 'outline'
                        },
                        _hover: {
                            color: 'text',
                            backgroundColor: isLightColorScheme ? 'gray.100' : 'gray.600'
                        }
                    },
                    dialog: {
                        bg: 'background',
                        borderRadius: {
                            base: 'none',
                            lg: 'xl'
                        },
                        my: {
                            base: 0,
                            lg: '60px'
                        }
                    },
                    overlay: {
                        bg: isLightColorScheme ? 'blackAlpha.600' : 'whiteAlpha.600'
                    }
                },
                sizes: {
                    md: {
                        content: {
                            maxHeight: 'calc(100vh - 80px)'
                        },
                        dialog: {
                            height: {
                                base: 'full',
                                lg: 'auto'
                            },
                            maxWidth: {
                                base: 'full',
                                lg: 'xl'
                            }
                        }
                    }
                }
            },
            Popover: {
                baseStyle: {
                    body: {
                        padding: '20px 40px 40px 40px'
                    },
                    content: {
                        backgroundColor: isLightColorScheme ? 'background' : 'gray.600',
                        border: 'none',
                        borderRadius: 'xl',
                        filter: dropShadow,
                        width: '380px',
                        _focus: {
                            boxShadow: 'none'
                        },
                        _focusVisible: {
                            boxShadow: 'outline'
                        }
                    }
                },
                variants: {
                    small: {
                        body: {
                            padding: '10px 0'
                        },
                        content: {
                            width: 'initial'
                        }
                    }
                }
            },
            Radio: {
                baseStyle: {
                    control: {
                        _checked: {
                            _before: {
                                bg: 'brand.primary' // Set the custom background color for the inner circle
                            }
                        }
                    }
                },
                variants: {
                    primary: {
                        control: {
                            _checked: {
                                background: 'brand.primary',
                                borderColor: 'brand.primary',
                                _before: {
                                    background: isLightColorScheme ? 'black' : 'white'
                                },
                                _hover: {
                                    background: 'brand.primary',
                                    borderColor: isLightColorScheme ? 'black' : 'white'
                                }
                            }
                        }
                    }
                }
            },
            Text: {
                baseStyle: {
                    color: 'text',
                    lineHeight: 'base',
                    margin: 0,
                    fontFamily: openSansFont
                },
                variants: {
                    error: {
                        color: isLightColorScheme ? 'red.600' : 'red.400'
                    }
                }
            },
            Textarea: {
                variants: {
                    outline: {
                        borderColor: 'gray.300',
                        _focus: {
                            borderColor: 'brand.primary',
                            borderWidth: '2px',
                            boxShadow: 'none'
                        },
                        _hover: {
                            borderColor: 'brand.primary',
                            borderWidth: '2px'
                        },
                        _invalid: {
                            borderColor: isLightColorScheme ? 'red.600' : 'red.400',
                            borderWidth: '2px',
                            boxShadow: 'none'
                        }
                    }
                }
            },
            Tooltip: {
                baseStyle: {
                    background: isLightColorScheme ? 'background' : 'gray.600',
                    // This workaround is necessary, because Chakra has a bug and won't set the bg of the tooltip's arrow:
                    // See https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
                    [cssVar('popper-arrow-bg').variable]: isLightColorScheme
                        ? 'var(--chakra-colors-white)'
                        : 'var(--chakra-colors-gray-600)',
                    borderRadius: 'lg',
                    boxShadow: 'none',
                    color: 'text',
                    filter: dropShadow,
                    fontFamily: openSansFont,
                    fontSize: '14px',
                    lineHeight: 'tall',
                    padding: '16px',
                    zIndex: 10000
                }
            }
        },
        filters: {
            dropShadow
        },
        fontColor: {
            default: 'text'
        },
        // Avoid applying fonts directly to the body and other types to avoid conflicts with other styles
        fonts: {
            body: '',
            heading: '',
            mono: ''
        },
        fontSizes: {
            xs: '12px',
            sm: '14px',
            md: '16px',
            lg: '20px',
            xl: '24px',
            '2xl': '28px',
            '3xl': '40px',
            '4xl': '44px',
            '5xl': '52px'
        },
        isLightColorScheme,
        letterSpacings: {
            wide: '1px',
            wider: '2px'
        },
        radii: {
            md: '3px',
            lg: '4px',
            xl: '20px'
        },
        shadows: {
            md: `0 0 4px 2px ${'var(--chakra-colors-gray-100)'}`
        },
        sizes: {
            xs: '320px',
            sm: '400px',
            xl: '600px'
        }
    };

    return theme;
};

export default getTheme;
