import React, { useMemo } from 'react';
import { Flex, FormControl, FormLabel, Grid, Heading, Input } from '@chakra-ui/react';
import { type UseFormRegister, useForm } from 'react-hook-form';
import { ButtonProps } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import ResponsiveButtonGroup from 'client_react/booking/components/ResponsiveButtonGroup';
import { useClientBookingData } from './App';
import { ROUTE } from './common';

interface ContactFieldValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

const ContactFormField = ({
    fieldName,
    inputType,
    register
}: {
    fieldName: keyof ContactFieldValues;
    inputType?: 'email' | 'tel' | 'text';
    register: UseFormRegister<ContactFieldValues>;
}) => {
    const t = useTranslate('booking.contactInformationPage');

    return (
        <FormControl isRequired maxWidth="calc(100vw - 32px)" padding={0}>
            <FormLabel>{t(fieldName)}</FormLabel>
            <Input
                boxSizing="border-box"
                width="100%"
                type={inputType}
                {...register(fieldName, {
                    required: true
                })}
            />
        </FormControl>
    );
};

const ContactInformationPage = () => {
    const {
        formState: { isSubmitting, isValid },
        handleSubmit,
        register
    } = useForm<ContactFieldValues>({ mode: 'onTouched' });

    const { bookingSession, isPending, updateBookingSession, navigateWithSession } =
        useClientBookingData();

    const t = useTranslate('booking.contactInformationPage');

    const handleSubmitForm = handleSubmit(async (contactFieldValues: ContactFieldValues) => {
        const success = await updateBookingSession({
            contact: {
                type: 'contact',
                ...contactFieldValues
            }
        });

        if (success) {
            if (bookingSession?.price && bookingSession.price > 0) {
                navigateWithSession(ROUTE.PAYMENT);
            } else {
                navigateWithSession(ROUTE.CONFIRMATION);
            }
        }
    });

    const buttons = useMemo<ButtonProps[]>(
        () => [
            {
                text: t('back'),
                onClick: () => navigateWithSession(ROUTE.DATE_AND_TIME),
                variant: 'outline primary'
            },
            {
                text: t('continue'),
                isDisabled: isPending || isSubmitting,
                variant: 'primary',
                type: 'submit',
                disabled: !isValid
            }
        ],
        [isPending, isSubmitting, isValid, navigateWithSession, t]
    );

    if (!bookingSession) {
        return null;
    }

    return (
        <Flex as="form" direction="column" gap="24px" onSubmit={handleSubmitForm}>
            <Heading as="h3" size="lg" margin={0}>
                {t('heading')}
            </Heading>
            <Grid gap="16px 24px" gridTemplateColumns="repeat(auto-fill, minmax(400px, 1fr))">
                <ContactFormField fieldName="firstName" register={register} />
                <ContactFormField fieldName="lastName" register={register} />
                <ContactFormField fieldName="email" inputType="email" register={register} />
                <ContactFormField fieldName="phone" inputType="tel" register={register} />
            </Grid>
            <ResponsiveButtonGroup buttons={buttons} />
        </Flex>
    );
};

export default ContactInformationPage;
